<!--
 * @Author: SongYijie
 * @Date: 2020-03-18 15:57:11
 * @LastEditors: SongYijie
-->
<template>
  <div class="isSelect">
    <img src="@assets/images/individualBusiness/select.png" alt="select" v-if="isSelect" />
    <img src="@assets/images/individualBusiness/unselect.png" alt="select" v-else />
  </div>
</template>

<script>
export default {
  props: ['isSelect'],
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang='less' scoped>
.isSelect {
  width: 5.0667vw;
  height: 5.0667vw;
  display: flex;
  align-items: center;
}
img {
  width: 5.0667vw;
  height: 5.0667vw;
}
</style>
