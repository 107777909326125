<template>
  <div class="inb">
    <top-banner/>
    <div class="content">
      <div class="registered" @click="handleNext">
        <img src="@assets/images/individualBusiness/registered.png" alt="registered" />
        <p v-if="this.state < 34">注册个体工商户</p>
        <p v-else>我的发票</p>
      </div>
      <div class="license" @click="handleLicense">
        <img src="@assets/images/individualBusiness/license.png" alt="license" />
        <p>我的执照</p>
      </div>
    </div>
    <div class="select" @click="handleSelectArea" v-if="areaOption.showSelect">
      <p v-if="!areaOption.isAllHaveBusiRegdNo">园区选择: {{areaOption.selected.name}}</p>
      <p v-else>个体户选择: {{areaOption.selected.busiRegdName}}</p>
    </div>
    <div class="process">
      <div class="id-info">
        <p :class="{'acticity': state > 0 , 'title': true, 'first-title': true }">身份信息</p>
        <div class="process-list">
          <div class="process-item">
            <isSelectImg :isSelect="state > 0" />
            <p>基本信息</p>
          </div>
          <div class="process-item">
            <isSelectImg :isSelect="state > 1" />
            <p>人脸识别</p>
          </div>
        </div>
      </div>
      <div class="base-info">
        <p :class="{'acticity': state > 10, 'title': true }">信息确认</p>
        <div class="process-list">
          <div class="process-item">
            <isSelectImg :isSelect="state > 10" />
            <p>用户确认</p>
          </div>
          <div class="process-item">
            <isSelectImg :isSelect="state > 12" />
            <p @click="handleShowProtocol">{{baseInfo.contractUrl ? '合同查看' : '合同签署'}}</p>
          </div>
        </div>
      </div>
      <div class="license-info">
        <p :class="{'acticity': state > 20, 'title': true }">营业执照办理</p>
        <div class="process-list">
          <div class="process-item">
            <isSelectImg :isSelect="state > 20" />
            <p>等待办理</p>
          </div>
          <div class="process-item">
            <isSelectImg :isSelect="state > 21" />
            <p @click="handleLicense">查看执照</p>
          </div>
        </div>
      </div>
      <div class="tax-info">
        <p :class="{'acticity': state > 30, 'title': true }">税务登记</p>
        <div class="process-list">
          <div class="process-item">
            <isSelectImg :isSelect="state > 30" />
            <p>税务登记</p>
          </div>
          <div class="process-item">
            <isSelectImg :isSelect="state > 33" />
            <p v-if="this.state === 32 || this.state === 33" :style="{color: '#2ba2f9'}" @click="handleShowPasswordMsg">电子税务局注册</p>
            <p v-else>电子税务局注册</p>
          </div>
        </div>
      </div>
    </div>
    <mt-actionsheet
      :actions="areaOption.actions"
      v-model="areaOption.sheetVisible"
    >
    </mt-actionsheet>
  </div>
</template>

<script>
import { getInBuBaseInfo, getUserSelfEEList, getElecTaxPassword } from '@api/individualBusiness';
import { getInBuState } from '@utils/common';
import isSelectImg from "@components/individualBusiness/isSelect";
import topBanner from "@components/individualBusiness/inbu-top-banner";

import { Toast, MessageBox } from 'mint-ui';
export default {
  name: "individualBusiness",
  data() {
    return {
      baseInfo: {},
      state: 0,
      areaOption: {
        actions: [
        ],
        sheetVisible: false,
        selected: {
        },
        isAllHaveBusiRegdNo: false,
        showSelect: false
      }
    };
  },
  components: {
    isSelectImg,
    topBanner
  },
  mounted() {
    getUserSelfEEList().then(res => {
      if (res.code === 200) {
        let isAllHaveBusiRegdNo = true;
        const { data } = res;
        const sessionAreaId = sessionStorage.getItem('area');
        if (Array.isArray(data) && data.length === 2) {
          data.forEach(item => {
            if (!item.busiRegdNo || !item.busiRegdName) {
              isAllHaveBusiRegdNo = false
            }
            this.areaOption.actions.push({
              name: item.region === 1 ? '温州-龙港' : '温州-苍南',
              region: item.region,
              busiRegdNo: item.busiRegdNo,
              busiRegdName: item.busiRegdName,
              method: () => this.handleSelectedInbu(item)
            })
            if (sessionAreaId && item.region === Number(sessionAreaId)) {
              this.areaOption.selected = {
                name: item.region === 1 ? '温州-龙港' : '温州-苍南',
                region: item.region,
                busiRegdNo: item.busiRegdNo,
                busiRegdName: item.busiRegdName
              }
              this.handleGerBaseInfo(item)
            }
          });
          this.areaOption.isAllHaveBusiRegdNo = isAllHaveBusiRegdNo;
          this.areaOption.showSelect = true;

          if (isAllHaveBusiRegdNo) {
            this.areaOption.actions = this.areaOption.actions.map(item => ({
              ...item,
              name: item.busiRegdName
            }))
          }
          
          if (!sessionAreaId) {
            this.areaOption.selected = {
              name: data[0].region === 1 ? '温州-龙港' : '温州-苍南',
              region: data[0].region,
              busiRegdNo: data[0].busiRegdNo,
              busiRegdName: data[0].busiRegdName
            }
            sessionStorage.setItem('area', data[0].region)
            this.handleGerBaseInfo(data[0])
          }
        } else if (Array.isArray(data) && data.length === 1) {
          this.handleGerBaseInfo(data[0])
          this.areaOption.selected = {
            name: data[0].region === 1 ? '温州-龙港' : '温州-苍南',
            region: data[0].region,
            busiRegdNo: data[0].busiRegdNo,
            busiRegdName: data[0].busiRegdName
          }
          sessionStorage.setItem('area', data[0].region)
        }
      } else {
        Toast(res.message);
      }
    })
  },
  beforeDestroy() {
    const messageBox = document.getElementsByClassName('mint-msgbox-btn mint-msgbox-cancel ');
    const pNode = document.getElementById('show-ex');
    if (pNode) {
      pNode.removeEventListener('click', this.handleGotoExample, false);
      pNode.parentElement.removeChild(pNode);
    }
    if (messageBox && messageBox[0]) {
      messageBox[0].click()
    }
  },
  computed: {},
  methods: {
    handleSelectedInbu(item) {
      this.handleGerBaseInfo(item)
        this.areaOption.selected = {
          name: item.region === 1 ? '温州-龙港' : '温州-苍南',
          region: item.region,
          busiRegdNo: item.busiRegdNo,
          busiRegdName: item.busiRegdName
        }
        sessionStorage.setItem('area', item.region)
    },
    handleSelectArea() {
      this.areaOption.sheetVisible = true;
    },
    handleGerBaseInfo(info) {
      this.baseInfo = info;
      this.state = getInBuState(info.state);
      if ((this.state === 33 || this.state === 32) && !info.taxPassword) {
        this.handleShowPasswordMsg();
      }
    },
    handleShowPasswordMsg() {
      MessageBox.prompt(' ', '请输入您收到的短信内的\n电子税务局初始密码:',{inputPlaceholder:'请输入密码'}).then(({ value, action }) => {
        window.scroll(0,0);
        if (action === 'confirm') {
          if (!value) {
            Toast('密码不能为空');
            return;
          }
          getElecTaxPassword({
            taxPassword: value
          }).then(res => {
            if (res.code === 200) {
              Toast('电子税务密码输入成功')
              this.baseInfo.taxPassword = value
            }
          })
        }
      }).catch(err => {
        window.scroll(0,0);
      });
      requestAnimationFrame(() => {
        const inputNode = document.querySelector('.mint-msgbox-input > input');

        if (document.getElementById('show-ex')) {
          return
        }
        const contentNode = document.getElementsByClassName('mint-msgbox-content')[0];
        const pNode = document.createElement('p');
        pNode.innerHTML = '查看示例';
        pNode.id = 'show-ex';
        contentNode.appendChild(pNode);
        pNode.addEventListener('click', this.handleGotoExample, false);
      })
    },
    handleGotoExample(e) {
      e.stopPropagation()
      e.preventDefault()
      this.$router.push('/individual-business/tax-password-example');
    },
    handleNext() {
      if (this.state === 0) {
        getInBuBaseInfo().then((res) => {
          if (res.code === 200) {
            this.$router.push('/individual-business/base-info');
          } else {
            Toast(res.message);
          }
        })
      } else if (this.state === 1) {
        this.$router.push('/individual-business/face-upload');
      } else if (this.state === 10) {
        this.$router.push('/individual-business/confirm-info');
      } else if (this.state === 12) {
        this.$router.push('/individual-business/protocol');
      } else if (this.state === 20) {
        Toast('营业执照办理中, 请稍等');
      } else if (this.state === 21) {
        Toast('营业执照办理中, 请稍等');
      // } else if (this.state === 30) {
      //   Toast('税务登记中, 请稍等');
      // } else if (this.state === 31) {
      //   Toast('税务登记中, 请稍等')
      } else if (this.state === 30 || this.state === 31 || this.state === 32 || this.state === 33) {
        this.$router.push('/individual-business/tax-prove');
      } else if (this.state >= 34) {
        this.$router.push('/individual-business/invoice');
      }
    },
    handleLicense() {
      if (this.state > 21 ) {
        this.$router.push('/individual-business/license');
      } else {
        Toast('暂无执照');
      }
    },
    handleShowProtocol() {
      const { state, baseInfo: {contractUrl} } = this;
      if (state > 12 ) {
        if (contractUrl) {
          window.location.href = contractUrl;
        } else {
          Toast('合同生成中, 请稍等');
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.select {
  color: #333333;
  margin-bottom: 2.6667vw;
}
.inb {
  width: 100%;
  background-color: #ffffff;
  padding: 4.267vw 4.8vw;
  margin-bottom: -28vw;
}
.content {
  margin: 3.7333vw 0 5.6vw;
  display: flex;
  justify-content: space-between;
}
.registered, .license {
  width: 43.2vw;
  height: 16vw;
  position: relative;

  > p {
    position: absolute;
    font-size: 4.2667vw;
    font-weight: 500;
    color: #ffffff;
    line-height: 6vw;
  }
}
.registered {
  > p {
    top: 5.0667vw;
    left: 10.2667vw;
  }
}
.license {
  > p {
    top: 5.0667vw;
    left: 17.2vw;
  }
}
.id-info, .base-info, .license-info, .tax-info {
  padding-left: 5.0667vw;
}
.title {
  height: 6vw;
  font-size: 4.2667vw;
  font-weight: 500;
  color: rgba(51,51,51,1);
  line-height: 6vw;
  position: relative;
  &::before {
    content: '';
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 50%;
    position: absolute;
    top: 2.2vw;
    left: -4.2667vw;
    background-color: #C7C7C7;
  }
  &::after {
    content: '';
    width: 0.2667vw;
    height: 20.8333vw;
    position: absolute;
    top: -16.8667vw;
    left: -3.4667vw;
    background-color: #C7C7C7;
  }
}
.first-title {
  &::after {
    display: none;
  }
}
.acticity {
  color: #D0021B;
  &::before {
    background-color: #C92E26;
  }
  &::after {
    background-color: #C92E26;
  }
}
.process-list {
  margin: 1.7333vw 0 7.3333vw;
}
.process-list, .process-item {
  display: flex;
  justify-content: start;
}
.process-item {
  width: 50%;
  height: 5.6vw;
  align-items: center;
  > p {
    height: 5.6vw;
    font-size: 4vw;
    font-weight: 300;
    color: rgba(102, 102, 102, 0.6);
    line-height: 5.6vw;
    margin-left: 2.6667vw;
  }
}
</style>