<template>
  <div class="inbu-swiper-wrap">
    <div class="inner-warp">
      <mt-swipe :auto="3000">
        <mt-swipe-item>
          <img src="https://xiyk.oss-cn-hangzhou.aliyuncs.com/20200927114140.png" alt="operator" @click="handleGotoVideo">
        </mt-swipe-item>
        <mt-swipe-item>
          <img src="https://xiyk.oss-cn-hangzhou.aliyuncs.com/20200927114122.png" alt="faq" @click="handleGotoFaq">
        </mt-swipe-item>
        <mt-swipe-item>
          <img src="https://xiyk.oss-cn-hangzhou.aliyuncs.com/20200927114134.png" alt="ad">
        </mt-swipe-item>
      </mt-swipe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    handleGotoFaq() {
      this.$router.push('/individual-business/faq')
    },
    handleGotoVideo() {
      this.$router.push('/individual-business/operate-video')
    }
  }
}
</script>

<style lang="less" scope>
  .inbu-swiper-wrap {
    height: 34.6667vw;
    padding-bottom: 34.6667vw;
    position: relative;
    .inner-warp {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      img {
        object-fit: cover;
      }
    }
  }
</style>

