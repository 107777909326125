import { render, staticRenderFns } from "./isSelect.vue?vue&type=template&id=1c5b4f80&scoped=true&"
import script from "./isSelect.vue?vue&type=script&lang=js&"
export * from "./isSelect.vue?vue&type=script&lang=js&"
import style0 from "./isSelect.vue?vue&type=style&index=0&id=1c5b4f80&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c5b4f80",
  null
  
)

export default component.exports